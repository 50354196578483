<template>
  <div>
    <label v-if="label" class="text-muted">{{ label }}</label>
    <div class="input-group align-items-center">
      <input
          type="text"
          class="form-control text-center small-padding-input"
          v-model="date.day"
          placeholder="ДД"
          maxlength="2"
          @input="handleInput('day', $event)"
          @keydown="handleBackspace('day', $event)"
          ref="day"
      />
      <input
          type="text"
          class="form-control text-center small-padding-input"
          v-model="date.month"
          placeholder="ММ"
          maxlength="2"
          @input="handleInput('month', $event)"
          @keydown="handleBackspace('month', $event)"
          ref="month"
      />
      <input
          type="text"
          class="form-control text-center"
          v-model="date.year"
          placeholder="ГГГГ"
          maxlength="4"
          @input="handleInput('year', $event)"
          @keydown="handleBackspace('year', $event)"
          ref="year"
      />
    </div>
    <small v-if="error" class="text-danger w-100 mt-1">Неверный формат даты.</small>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'SingleDateInput',
  props: {
    initialDate: Object,
    label: String
  },
  data() {
    const defaultDate = this.initialDate || (() => {
      const now = moment();
      return {
        day: now.format('DD'),
        month: now.format('MM'),
        year: now.format('YYYY'),
      };
    })();

    return {
      date: { ...defaultDate },
      error: false,
    };
  },
  watch: {
    date: {
      handler() {
        this.validateDate();
        this.emitDate();
      },
      deep: true,
    },
  },
  methods: {
    handleInput(type, event) {
      const nextField = { day: 'month', month: 'year' }[type];
      const isPaste = event.inputType === 'insertFromPaste';
      if (!isPaste && event.target.value.length === event.target.maxLength && nextField) {
        this.$refs[nextField]?.focus();
      }
      this.validateDate();
    },
    handleBackspace(type, event) {
      const prevField = { month: 'day', year: 'month' }[type];
      if (event.key === 'Backspace' && event.target.selectionStart === 0 && !event.target.value) {
        this.$refs[prevField]?.focus();
      }
    },
    validateDate() {
      let { day, month, year } = this.date;
      day = day.padStart(2, '0');
      month = month.padStart(2, '0');

      const isValidFormat =
          /^\d{2}$/.test(day) &&
          /^\d{2}$/.test(month) &&
          /^\d{4}$/.test(year);

      if (!isValidFormat) {
        this.error = true;
        return;
      }

      const dateStr = `${year}-${month}-${day}`;
      const parsed = moment(dateStr, 'YYYY-MM-DD', true);

      this.error = !parsed.isValid();
    },
    emitDate() {
      this.$emit('update', {
        date: this.date,
        valid: !this.error,
      });
    },
  },
};
</script>

<style scoped>
.small-padding-input {
  padding: 0 !important;
}
</style>
